<template>
  <b-card>
    <image-cropper
      v-if="selectedImage"
      :image="selectedImage"
      @cropped="imageCropped"
    />
    <b-row>
      <b-col md="12">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->

          <b-form
            v-if="blogContentData"
            class="p-2"
            @submit.prevent="handleSubmit(saveData)"
            @reset.prevent="resetForm"
          >
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <span><strong>Bilgilendirme:</strong> Eklenen içerik, seçilen başlangıç ve bitiş tarihleri arasında görüntülenebilir olacaktır. Bu tarih aralığı geçtiğinde otomatik olarak yayından kalkacaktır.</span>
              </div>
            </b-alert>
            <b-form-group

              :label="$t('Bildirim Türü')"
              label-for="m_type"
            >

              <v-select
                id="m_warning_type"
                v-model="blogContentData.content_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$staticParams.blogContentTypes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="m_warning_type"
              >
                <template #option="{ label }">

                  <span> {{ label }}</span>
                </template>
                <template #selected-option="{label }">

                  <span> {{ label }}</span>
                </template>
              </v-select>

            </b-form-group>
            <b-img
              style="max-height:200px;"
              :src="blogContentData.featured_image_url"
              fluid
            />
            <hr>
            <b-form-group
              :label="$t('Kategori Görseli')"
              label-for="image_file_path"
            >
              <b-form-file
                id="image_file_path"
                accept=".jpg, .jpeg"
                :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                @change="imageSelected"
              />

            </b-form-group>
            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('İçerik Başlığı')"
              rules="required|min:3"
            >

              <b-form-group
                :label="$t('İçerik Başlığı')"
                label-for="m_title"
              >
                <b-form-input
                  id="m_title"
                  v-model="blogContentData.title"
                  autofocus
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              v-if="blogContentData"
              :label="$t('Başlangıç Tarihi')"
              label-for="user_id"
            >
              <b-form-datepicker
                id="start_date"
                v-model="blogContentData.start_date"
                class="mb-1"
              />
            </b-form-group>
            <b-form-group
              v-if="blogContentData"
              :label="$t('Bitiş Tarihi')"
              label-for="user_id"
            >
              <b-form-datepicker
                id="end_date"
                v-model="blogContentData.end_date"
                class="mb-1"
              />
            </b-form-group>

            <b-form-group
              :label="$t('İçerik')"
              label-for="content"
            >
              <quill-editor
                id="content"
                v-model="blogContentData.content"
                :options="snowOption"
              />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
            </div>
          </b-form>

        </validation-observer>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton, BFormDatepicker, BAlert, BCard, BFormFile, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'
import vSelect from 'vue-select'

import blogContentStoreModule from '../blogContentStoreModule'

export default {
  components: {
    BForm,
    BRow,
    BCard,
    BFormFile,
    BImg,
    BCol,
    imageCropper,
    quillEditor,
    BAlert,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditblogContentSidebarActive',
    event: 'update:is-edit-blogContent-sidebar-active',
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      editorConfig: {
        // eslint-disable-next-line import/no-extraneous-dependencies
        placeholder: 'Let`s write an awesome story!',
        image: {
          // Like in https://github.com/editor-js/image#config-params
          config: {
            endpoints: {
              byFile: 'http://localhost:8090/image',
              byUrl: 'http://localhost:8090/image-by-url',
            },
            field: 'image',
            types: 'image/*',
          },

        },
      },

    }
  },
  watch: {
  },
  mounted() {
    const today = this.$moment()
    this.blankBlogContentData.start_date = today.format()
    this.blankBlogContentData.end_date = today.add(10, 'years').format()
    this.resetblogContentData()
    if (this.$router.currentRoute.params.id !== undefined) {
      this.blogContentData = null
      store.dispatch('app-blog-content/fetchSingleBlogContent', { id: this.$router.currentRoute.params.id })
        .then(response => {
          this.blankBlogContentData = response.data.data
          this.blogContentData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Error')
          }
        })
    }
  },
  methods: {
    imageCropped(croppedImage) {
      this.blogContentData.featured_image_url = croppedImage
      this.croppedImage = croppedImage
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null

      this.getBase64(file).then(data => { this.selectedImage = data })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeblogContentData() {
      this.blogContentData = JSON.parse(JSON.stringify(this.blankblogContentData))
    },

    saveData() {
      if (this.croppedImage != null) {
        this.blogContentData.upload_image = this.croppedImage
      }
      if (this.blogContentData.id === 0) {
        store.dispatch('app-blog-content/addBlogContent', this.blogContentData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.resetblogContentData()
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-blog-content/updateBlogContent', this.blogContentData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-blog-content'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, blogContentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })
    // fetch user
    const blogContentData = ref(null)
    const usersData = ref([null])
    const croppedImage = ref(null)
    const selectedImage = ref(null)
    const blankBlogContentData = ref({
      id: 0,
      title: '',
      start_date: null,
      end_date: null,
      content_type: 1,
      content: '',
      featured_image_url: '',
    })

    const onSubmit = () => {

    }
    const resetblogContentData = () => {
      blogContentData.value = JSON.parse(JSON.stringify(blankBlogContentData.value))
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetblogContentData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      croppedImage,
      blogContentData,
      blankBlogContentData,
      selectedImage,
      resetblogContentData,
      usersData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
